document.addEventListener("DOMContentLoaded", function () {
  const elements = {
    body: document.body,
    logoSvg: document.getElementById("logo-svg"),
    logoLetter: document.getElementById("logo-letter"),
    logoWord: document.getElementById("logo-word"),
    logoDot: document.getElementById("logo-dot"),
    navigation: document.getElementById("navigation"),
    mainMenu: document.getElementById("main-menu"),
    pullMenu: document.getElementById("pull-menu"),
    pullIcon: document.getElementById("pull-icon"),
    searchIcon: document.getElementById("search-icon"),
    searchForm: document.getElementById("search-form"),
    targetClasses: document.querySelectorAll(
      ".has-evening-inspo-background-color:not(.ignore-header), .has-max-boost-background-color:not(.ignore-header), .has-black-background-color:not(.ignore-header), .force-color-inversion",
    ),
  };

  // Function to handle animations for logo and navigation
  const handleAnimations = () => {
    const isMenuActive = elements.pullMenu.classList.contains("active");
    const isSearchFormActive = elements.searchForm.classList.contains("active");
    const shouldAnimate =
      window.scrollY > 15 || isMenuActive || isSearchFormActive;

    // Apply GSAP animations to the logo
    gsap.to(elements.logoWord, {
      autoAlpha: shouldAnimate ? 0 : 1,
      duration: 0.2,
    });

    gsap.to(elements.logoDot, {
      x: shouldAnimate ? -360 : 0,
      duration: 0.2,
      ease: "power1.out",
    });

    gsap.to(elements.logoSvg, {
      scale: shouldAnimate ? 1.33 : 1,
      duration: 0.2,
      ease: "power1.out",
    });

    gsap.to(elements.mainMenu, {
      autoAlpha: shouldAnimate ? 0 : 1,
      duration: 0.2,
    });
  };

  // Function to check if two elements intersect both vertically and horizontally
  const isIntersecting = (rect1, rect2) => {
    return (
      rect1.bottom > rect2.top && // vertically overlapping
      rect1.top < rect2.bottom && // vertically overlapping
      rect1.right > rect2.left && // horizontally overlapping
      rect1.left < rect2.right // horizontally overlapping
    );
  };

  // Function to handle logo inversion based on intersection with target background classes
  const handleLogoInversion = () => {
    const logoRect = elements.logoSvg.getBoundingClientRect();
    const intersectsTargetClass = Array.from(elements.targetClasses).some(
      (target) => {
        const targetRect = target.getBoundingClientRect();
        return isIntersecting(logoRect, targetRect); // Check for both vertical and horizontal intersection
      },
    );

    // Toggle the invert-color class on the logo elements
    if (intersectsTargetClass) {
      elements.logoSvg.classList.add("invert-color");
    } else {
      elements.logoSvg.classList.remove("invert-color");
    }
  };

  // Function to handle navigation inversion based on intersection with target background classes
  const handleNavigationInversion = () => {
    const navigationRect = elements.navigation.getBoundingClientRect();
    const intersectsNavTargetClass = Array.from(elements.targetClasses).some(
      (target) => {
        const targetRect = target.getBoundingClientRect();
        return isIntersecting(navigationRect, targetRect); // Check for both vertical and horizontal intersection
      },
    );

    // Toggle the invert-color class on navigation elements
    [elements.mainMenu, elements.pullIcon, elements.searchIcon].forEach(
      (element) => {
        if (intersectsNavTargetClass) {
          element.classList.add("invert-color");
        } else {
          element.classList.remove("invert-color");
        }
      },
    );
  };

  // Unified handler for inversions and animations
  const handleAnimationsAndInversions = () => {
    handleLogoInversion(); // Handle logo inversion based purely on intersection
    handleNavigationInversion(); // Handle navigation inversion based purely on intersection
    handleAnimations(); // Handle animations based on scroll and menu states
  };

  // Animate the pull menu with GSAP instead of CSS transitions
  const toggleMenuWithGSAP = (open) => {
    if (open) {
      // Slide menu in
      gsap.to(elements.pullMenu, {
        opacity: 1,
        y: 0, // Move to the original position
        duration: 0.2,
        onComplete: () => {
          handleAnimationsAndInversions(); // Run inversion checks after the menu is fully opened
        },
      });
    } else {
      // Slide menu out
      gsap.to(elements.pullMenu, {
        opacity: 0,
        y: "-100%", // Slide out of view
        duration: 0.2,
        onComplete: () => {
          handleAnimationsAndInversions(); // Recheck after the menu is fully closed
        },
      });
    }
  };

  // Animate the search form with GSAP
  const toggleSearchFormWithGSAP = (open) => {
    if (open) {
      // Slide search form in
      gsap.to(elements.searchForm, {
        opacity: 1,
        y: 0, // Move to the original position
        duration: 0.2,
        onComplete: () => {
          handleAnimationsAndInversions(); // Run inversion checks after the search form is fully opened
        },
      });
    } else {
      // Slide search form out
      gsap.to(elements.searchForm, {
        opacity: 0,
        y: "-100%", // Slide out of view
        duration: 0.2,
        onComplete: () => {
          handleAnimationsAndInversions(); // Recheck after the search form is fully closed
        },
      });
    }
  };

  // Toggle pull menu and update animations and inversions
  elements.pullIcon.addEventListener("click", () => {
    const isMenuActive = elements.pullMenu.classList.contains("active");
    elements.pullMenu.classList.toggle("active");
    elements.pullIcon.classList.toggle("active");
    elements.body.classList.toggle("no-scroll");
    toggleMenuWithGSAP(!isMenuActive); // Open or close the menu using GSAP
  });

  // Toggle search form and update animations and inversions
  elements.searchIcon.addEventListener("click", () => {
    const isSearchFormActive = elements.searchForm.classList.contains("active");
    elements.searchForm.classList.toggle("active");
    elements.searchIcon.classList.toggle("active");
    elements.body.classList.toggle("no-scroll");
    toggleSearchFormWithGSAP(!isSearchFormActive); // Open or close the search form using GSAP
  });

  // Attach event listeners for scroll and resize
  window.addEventListener("scroll", handleAnimationsAndInversions);
  window.addEventListener("resize", handleAnimationsAndInversions);

  // Initial call to set the correct state based on the current viewport and scroll position
  handleAnimationsAndInversions();
});
