// Description: Highlight words in a paragraph as you scroll down the page.
document.addEventListener('DOMContentLoaded', () => {
    const paragraph = document.querySelector('.is-style-highlight');

    if (paragraph) {
        const split = new SplitText(paragraph, {type: 'words'});
        const words = split.words; // Array of word elements

        gsap.set(words, {color: 'var(--wp--preset--color--grey)'});

        ScrollTrigger.create({
            trigger: paragraph,
            start: "top bottom-=150", // Adjust these values based on your needs
            end: "bottom top+=300",
            scrub: true,
            onUpdate: self => {
                const progress = self.progress;
                const totalWords = words.length;
                const wordsToHighlight = Math.floor(totalWords * progress);
                words.forEach((word, index) => {
                    word.style.color = index < wordsToHighlight ? 'black' : 'var(--wp--preset--color--grey)';
                });
            }
        });
    }
});